<template>
  <div>
    <topbar :title="gengGaiMiMa"></topbar>
    <div class="password">
      <input
        type="text"
        :placeholder="xianYouMima"
        @input="changeBtn"
        v-model.trim="oldPas"
      />
    </div>
    <div class="password">
      <input
        type="text"
        :placeholder="xinMiMa"
        @input="changeBtn"
        v-model.trim="newPas"
      />
    </div>
    <div class="alertText">{{ miMaGuiZe }}</div>
    <div class="editBtn" @click="editPas" :class="{ active: showBtn }">
      {{ change }}
    </div>

    <confirm-box
      v-show="showErr"
      :show="false"
      :title="title"
      :finishBtn="finishBtn"
      @finish="finish"
    ></confirm-box>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import language from "../../assets/js/language";
import ConfirmBox from "../../components/common/CnfirmBox.vue";

import { postPaas } from "../../assets/utils/request";
export default {
  name: "EditPas",
  data() {
    return {
      oldPas: "",
      newPas: "",
      showBtn: false,
      showErr: false,
      lanType: this.$store.state.language,

      finishBtn: this.$store.state.language == 1 ? "確認" : "Confirm",
      gengGaiMiMa: "",
      xianYouMima: "",
      xinMiMa: "",
      change: "",
      miMaGuiZe: "",
      title: "",
    };
  },
  methods: {
    changeBtn() {
      if (this.oldPas.length >= 6 && this.newPas.length >= 6) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
    async editPas() {
      if (!this.showBtn) return;
      let params = {
        actionName: "candao.member.passwordUpdate",
        content: {
          oldPassword: this.oldPas,
          newPassword: this.newPas,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.$router.back(-1);
      } else {
        this.showErr = true;
        // this.title = result.msg;
      }
    },
    finish() {
      this.showErr = false;
    },
    // 設置語言
    setlanguage() {
      var type = "chinese";
      if (this.lanType != 1) {
        type = "english";
      }
      this.gengGaiMiMa = language.gengGaiMiMa[type];
      this.xianYouMima = language.xianYouMima[type];
      this.xinMiMa = language.xinMiMa[type];
      this.change = language.EP[type];
      this.miMaGuiZe = language.miMaGuiZe[type];
      this.title = language.EDIT_error[type];
    },
  },
  created() {
    this.setlanguage();
  },
  components: {
    Topbar,
    ConfirmBox,
  },
};
</script>
<style scoped>
.password {
  margin: 0 0.16rem;
  height: 0.61rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid #d9d9d9;
}
.password input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.16rem;
  font-family: Open Sans;
  font-weight: 400;
  color: #b1b1b3;
}
.editBtn {
  margin: 0.48rem 0.16rem 0;
  height: 0.44rem;
  background-color: #cacbcc;
  border-radius: 0.08rem;
  font-size: 0.16rem;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 0.44rem;
  color: #808080;
}
.editBtn.active {
  background-color: #e4022b;
  color: #fff;
}
.alertText {
  position: absolute;
  width: 100%;
  height: 0.14rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.12rem;
  line-height: 0.14rem;
  color: #474747;
  text-align: left;
  margin-top: 0.05rem;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
</style>